.sports-overview {
  text-align: center;
  margin: auto;
  display: block;
  padding-top: 65px;

  .title {
    padding: 17px;
    background: white;
    font-size: 18px;
    border-bottom: 1px solid #D3D3FF;
  }

  .sport-line, .user {
    background: rgba(211, 211, 255, 0.3);
    width: 40%;
    min-height: 46px;
    display: flex;
    margin: 0 auto 16px auto;

    .bold-text {
      flex: 1;
      margin: auto;
      text-align: left;
      margin-left: 20px;
      font-size: 16px;
    }
    .fab-button {
      cursor: pointer;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 90px;
      box-shadow: 0 0 10px -5px;
      margin: 9px 16px;
      background: white;
      border: none;

      &:active {
        background: #FFB40D;

        path {
          fill: white;
        }
      }

      svg {
        width: 15px;
        height: 15px;
        margin: auto;
        display: flex;
      }
    }
  }

  .user {
    background: transparent;
    display: block;
    text-align: left;
    margin-bottom: 10px;
    
    .avatar {
      height: 30px;
      width: 30px;
      vertical-align: middle;
      border-radius: 45px;
      margin: 8px 10px 8px 30px;
    }

    .fullname {
      display: inline-block;
    }
  }

  .form-button {
    font-size: 16px;
    margin: 10px auto 28px auto;
    width: 280px;
  }

  @media (max-width: 425px) {
    .sport-line, .user {
      width: 100%;
    }
  }
}
