.birth-year-dialog {
  .form-field {
    width: 70px;
    margin: 20px auto 35px auto;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}