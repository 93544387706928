@import '../../../../App.scss';

.sport-linked-dialog {
  .title {
    margin-bottom: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
  }

  .current-sport {
    display: inline-block;
    width: fit-content;

    .pictogram-label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .sport-pictogram {
      margin: auto;
    }
  }

  .friends {
    width: fit-content;
    margin: auto;
  
    .friend-user.user {
      text-align: left;
      background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%);
      border-radius: 45px;
      width: fit-content;
      margin: 30px auto 50px auto;
      .full-name {
        color: white;
        display: inline-block;
        vertical-align: middle;
        margin-right: 28px;
        font-weight: bold;
      }
    }
    .friend-user:not(:first-child) {
      border-color: $button-color;
    }
  }

  .like_to_try {
    border: 2px solid $button-color;
  }
  .already_played {
    border: 2px solid #4D6ED3;
  }

  .friends-page-button {
    cursor: pointer;
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.20);
    border-radius: 33px;
    background: white;
    border: none;
    padding: 10px;
    min-width: 140px;
    img {
      margin-right: 10px;
    }
  }

  .link {
    cursor: pointer;
    display: block;
    margin: 50px auto 0 auto;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
  }
  .sport-linked__avatar {
    width: 50px;
    height: 50px;
    border-radius: 45px;
    margin: 7px;
    vertical-align: middle;
  }
}