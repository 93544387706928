.welcome-dialog {
  position: relative;

  .img--language {
    display: inline-block;
    background-position: center;
    background-size: cover;
    width: 35px;
    height: 20px;
    margin-left: 10px;
    border: 1px solid deepskyblue;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
  }
  .text--lang {
    width: 35px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    color: inherit;
    font-weight: bold;
    padding-top: 2px;
    font-size: 12px;
    vertical-align: middle;
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.23) 0%, rgba(0, 136, 255, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0088ff', GradientType=1);
  }
  .form-button {
    margin: 15px 0 40px 0;
  }
}

