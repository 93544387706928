@import '../../../App.scss';

.profile {
  width: 100% !important;

  .profile-field {
    display: inline-grid;
    // grid-template-columns: 25% 25% 25% 25%;
  }

  .label {
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #3F3F3F;
    line-height: 23px;
    text-align: left;
    text-transform: capitalize;
  }

  .title {
    margin: 20px 0;
  }

  .personal-info {
    display: inline-grid;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
    margin-bottom: 12px;

    .coloumn {
      margin: 0 16px;
      vertical-align: top;
      text-align: center;

      .profile-field {
        width: 100%;
        .form-field {
          width: initial;
          max-width: 100%;
        }
      }

      .avatar-field {
        width: fit-content;
        margin: 0 auto 56px auto;
        .avatar, .avatar-img {
          border-radius: 180px;
          width: 250px;
          height: 250px;

          @media (max-width: 320px) {
            width: 200px;
            height: 200px;
          }
        }

        .avatar {
          background: $gradient-vertical;
          padding: 8px;
        }
        .avatar-img {
          background: no-repeat center;
          background-size: cover;
          object-fit: cover;
        }
        .upload-avatar {
          display: none;
        }
        .icon {
          cursor: pointer;
          background: $button-color;
          display: block;
          padding: 10px;
          border-radius: 90px;
          width: fit-content;
      
          margin: -23px auto;
          z-index: 1;
          position: relative;
      
          img {
            width: 30px;
            z-index: 1;
            position: relative;
          }
        }
        .icon:hover {
          background: darken($button-color, 2);
        }
        .icon:active {
          background: darken($button-color, 3);
        }
      }
    
      .birth-date {
        width: 100%;
        margin: 10px 5px;
        display: inline-grid;
    
        .form-field {
          text-align: center;
          width: 64px;
          margin-right: 8px;
          max-width: calc(30% - 36px);
        }
        .form-field:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
    
      .time-select {
        border: 1px solid #E3E3EB;
        box-shadow: 0 9px 12px 0 rgba(184,184,216,0.30);
        border-radius: 3px;
        height: 100%;
        width: 100%;
        background: white;
        padding: 6px;
        text-align: center;
        align-items: center;

        @media (max-width: 1024px) {
          padding: 0;
        }
      }
    }

    @media (max-width: 1024px) {
      grid-template-columns: 100%;
    }

    @media (max-width: 2560px) {
      max-width: 1440px;
    }

    @media (max-width: 1440px) {
      max-width: 1024px;
    }
  }

  hr {
    opacity: 0.2;
    border: 1px solid #979797;
  }

  .company-info {
    .profile-field {
      // width: 25%;
      margin: 0 16px;
    }
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;

    .coloumn {
      margin: 0 !important;
    }

    .profile-field {
      width: 100%;
      margin: 0;

      input {
        width: calc(100% - 34px);
      }
    }
  }
  
  .form-button {
    display: block;
    margin: auto;
    margin-top: 16px;
    padding: 14px 40px;
    width: 300px;
    max-width: 100%;
    letter-spacing: 1px;
  }
}
