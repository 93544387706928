.share-dialog {
  margin: 10px;

  .share-code {
    font-size: 24px;
    color: #00CA9D;
    text-align: center;
    background: #D3FFF5;
    border: 2px dashed #00CA9D;
    border-radius: 3px;
    padding: 18px;
    font-weight: bold;
  }

  .form-button {
    margin-top: 20px;
    width: 100%;
  }

  .dialog-description {
    width: 255px;
    display: inline-block;
  }
}
