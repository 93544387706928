@import '../../App.scss';

.image-viewer.swiper-container {
  max-width: 90%;
  width: 350px;
  height: 390px;
  box-shadow: 0 0 30px -5px;
  border-radius: 30px;

  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin: auto;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;
      display: inline-block;
      border-radius: 30px;
      background-size: cover;
    }
  }

  .sport-title {
    display: block;
    position: absolute;
    border-radius: 17.5px;
    padding: 3px;
    min-width: 136px;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
    background: $gradient-vertical;
    font-weight: bold;
    color: $text-color;
    z-index: 1;
    
    div {
      border-radius: 17.5px;
      background: white;
      padding: 5px 15px;
    }
  }
  
  .swiper-pagination {
    background: #2D2D2D;
    height: 10px;
    padding: 3px 4px;
    position: relative;
    display: flex;
    margin: -50px auto 34px auto;
    border-radius: 20px;
    width: 28px;

    .control {
      cursor: pointer;
      background: white;
      width: 8px;
      height: 8px;
      display: flex;
      margin: auto 3px;
      border-radius: 90px;
    }
    .control.swiper-pagination-bullet-active {
      background: $gradient-horizontal;
    }
  }

  @media(max-height: 600px) {
    height: 300px;
  }

  @media(max-height: 500px) {
    height: 250px;
  }
}
