.attach-location {
  .dialog-description {
    width: 270px;
    margin: 0 auto 30px auto;
  }
  .form-dropdown {
    max-width: calc(100% - 24px);
    width: 100%;
  }
}
