@import '../../App.scss';

.main-page {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .favorite-poll {
    margin: auto;
    position: relative;
    text-align: center;
    width: 100%;

    .wiki-link {
      background-image: linear-gradient(0deg, #CFCFCF 0%, #FFFFFF 100%);
      border-radius: 17.5px;
      width: 152px;
      margin: -21px auto 18px auto;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      display: flex;
      padding: 0;
      color: inherit;
      text-decoration: inherit;
      align-items: center;
    
      span {
        display: inline-flex;
        margin: auto;
      }
    
      .wiki-logo {
        margin: 0;
        background-image: linear-gradient(177deg, #EAEAEA 28%, #A3A3A3 73%);
        border-radius: 45px;
        padding: 7px;
        font-weight: 100;
      }
    }
    
    .wiki-link:hover {
      box-shadow: 0 0 10px -5px;
    }
    
    .wiki-link:active {
      box-shadow: 0 0 10px -4px;
    }
    
    .action {
      width: 86px;
      margin: 4px;
      display: inline-block;
      vertical-align: top;
      font-size: 10px;
      padding: 10px 0;
      border-radius: 24px;
      cursor: pointer;
      background: transparent;
      border: none;

      .selected-not_interested {
        background: #DB3939;
      }
      .selected-like_to_try {
        background: #40CA9D;
      }
      .selected-already_played {
        background: #7B95C9;
      }
      .selected-undo {
        background: #FFB40D;
      }

      [class*='selected-'] {
        path {
          fill: white;
        }
      }

      .fab-button, .fab-button-min {
        height: 67px;
        width: 67px;
        display: flex;
        border-radius: 90px;
        box-shadow: 0 0 2px 1px lightgrey;
        margin: 10px auto;
        justify-content: center;
        align-items: center;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .fab-button-min {
        height: 50px;
        width: 50px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .action:disabled {
      pointer-events: none;
      .fab-button, .fab-button-min {
        background: #dedede;
      }
    }

    .control-buttons {
      display: flex;
      width: 100%;

      .action:first-child {
        margin-left: auto;
      }

      .action:last-child {
        margin-right: auto;
      }

      @media (max-width: 767px) {
        position: fixed;
        bottom: 0;
      }
    }
  }

  @media (max-height: 700px) {
    .favorite-poll {
      margin-top: 100px;
    }
  }
}
