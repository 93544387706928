@import '../../../../App.scss';

.new-friend-dialog {
  .gif-viewer {
    background: #FFFFFF;
    border-radius: 22px;
    width: 175px;
    height: 175px;
    margin: auto;
  }
  canvas {
    position: fixed !important;
  }
}