@import '../../App.scss';
$header-img-wave: url("../../assets/img/img_BackGroundWaves_top.png");
$body-img-wave: url("../../assets/img/img_BackGroundWaves3.png");

.header {
  width: 100%;
  height: 40%;
  background: $header-img-wave no-repeat scroll center center / 100% 100%,
              $gradient-horizontal;

  .title-block {
    width: min-content;
    margin: auto;
    padding: 5% 0;
    text-align: center;

    color: white;
    font-size: 18px;
    font-weight: bold;

    img {
      width: 130px;
      margin-top: 10px;
    }
  }
}

.main-block {
  background: $body-img-wave no-repeat scroll center center / 100% 50%;
  background-position-y: 100%;
  display: flex;
  height: 59vh;
  text-align: center;
  margin: auto;
  font-size: 16px;
  color: $text-color;

  hr {
    width: 282px;
    opacity: 0.2;
    border: 1px solid #979797;
  }
    
  .content {
    width: 302px;
    margin: auto;

    .language-block {
      width: 185px;
      margin: auto;
      
      .lang-icon {
        margin: 0 10px;
        cursor: pointer;
        box-shadow: 0 0 13px -5px rgba(0, 0, 0, 0.75);
        border-radius: 50%;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .email-block {
      button {
        margin-top: 25px;
      }
      .description {
        margin: 16px auto;
        width: 230px;
      }
    }
  }

  .link-button {
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 14px;
    text-decoration: underline;
    color: #00b189;

    &:hover {
      color: orangered;
    }
  }
}
