@import '../../App.scss';

.app-header {
  background: $gradient-horizontal;
  width: 100%;
  height: 65px;
  display: flex;
  color: white;
  position: fixed;
  z-index: 2;

  .nav-back {
    margin-left: 18px;
    padding: 10px;
    cursor: pointer;
  }

  .page-name {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .title {
      display: block;
      color: white;
    }    
    .description {
      font-size: 14px;
    }
  }

  .menu-btn {
    margin: 12px 20px;
    cursor: pointer;
    margin-left: -41px;
  }
  .menu-btn:hover {
    filter: drop-shadow(0px 0px 1px grey);
  }
}
