.password-dialog {
  position: relative;
  text-align: center;

  input {
    width: 189px;
    padding-right: 70px;
  }

  .form-button {
    width: 170px;
    top: 75px;
    margin-bottom: 20px;
    display: inline-block;
  }

  .formatted-text {
    display: inline-block;
    width: 200px;
    margin: 20px;
  }
}

.password-visibility {
  position: absolute;
  right: 0;
  top: 3px;
  width: 49px;
  height: 49px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: coral;

  &:hover {
    color: orangered;
  }
}

