$text-color: #3F3F3F;
$button-color: #00CA9D;
$input-color: #E3E3EB;
$gradient-horizontal: linear-gradient(90deg, rgba(247,109,29,1) 10%, rgba(255,204,39,1) 100%);
$gradient-vertical: linear-gradient(0deg, rgba(247,109,29,1) 10%, rgba(255,204,39,1) 100%);

.logo-base {
  position: absolute;
  top: 20px;
  width: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.form-field {
  background: white;
  font-size: 16px;
  padding: 17px;
  width: 280px;
  max-width: 88%;
  margin-bottom: 20px;
  border: 1px solid #E3E3EB;
  box-shadow: 0 9px 12px 0 rgba(184,184,216,0.30);
  border-radius: 3px;
}

::placeholder {
  font-size: 16px;
  color: rgba(63, 63, 63, 0.2);
  line-height: 23px;
}

.form-button {
  background: $button-color;
  border-radius: 27px;
  padding: 14px;
  font-weight: bold;
  border: 0;
  width: 254px;

  font-family: 'Montserrat';
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  cursor: pointer;
}

.form-button:hover {
  background: darken($button-color, 5);
}

.form-button:active {
  background: darken($button-color, 10);
}

.form-button:disabled {
  background: grey;
  pointer-events:none;
}

.title, .bold-text {
  font-size: 18px;
  font-weight: bold;
  color: $text-color;
}

.sport-pictogram {
  display: flex;
  width: 30px;
  height: 27px;
  margin: 4px 0 0 20px;
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(82, 98, 78, 0.3);
  border-radius: 3px;
  padding: 5px 10px;
  
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin: auto;
    display: flex;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  padding-bottom: 30px;
}

.loader-container--transparent {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 9;

  .loader-active {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.loader-item--orange {
  div {
    background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%);
  }
}

button {
  outline: none;
}

.form-button--onboarding {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: none;
  cursor: pointer;
}

.form-button--rect {
  border-radius: 8px;
  @extend .form-button--onboarding;
}

.form-button--orange {
  @extend .form-button--onboarding;
  border: 1px solid darkorange;
  color: dimgrey;
  background-color: white;

  &:disabled {
    background-color: lightgrey;
  }
  &:hover {
    color: dimgrey;
    background-color: white;
  }
  &:active {
    background-color: darkorange;
    color: white;
  }
}

.form-button--orange-fill {
  @extend .form-button--onboarding;
  border: 1px solid deepskyblue;
  background-color: darkorange;
  color: white;

  &:hover {
    background-color: deepskyblue;
  }
}

.form-button--link {
  background: transparent;
  display: inline-block;
  color: grey;
  text-decoration: underline;
  @extend .form-button--onboarding;

  &:hover {
    color: darkorange;
    background: transparent;
  }
}

.button__container {
  margin: 20px 0 30px;
}

.mt-4 {
  margin-top: 16px;
}

.formatted-text {
  display: inline-block;
  width: 225px;
}

.mw-180 {
  max-width: 180px;
}

.bg--orange {
  background-color: orange;
}

.bg--grey {
  background-color: lightgrey;
}

.filler {
  width: 100%;
  height: 4px;
}

.position-relative {
  position: relative;
}

.pictogram-wrapper {
  margin: auto;
  .pictogram {
    width: auto!important;
    height: 130px;
  }
}

.error-message {
  color: orangered;
  margin-bottom: 10px;
  font-weight: 600;
}