.save-email-dialog {
  position: relative;

  .description {
    width: 274px;
    margin: auto;
  }
}

.identity-button-2 {
  width: 175px;
  display: block;
  margin: 20px auto 40px;
  border: none;
  background: white;
}
