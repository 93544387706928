@import '../../../../App.scss';

.gender-selection {
  text-align: center;

  .gender-button {
    display: inline-block;
    width: fit-content;
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;

    .gender-icon {
      cursor: pointer;
      box-shadow: 0 2px 6px 0 rgba(82,98,78,0.30);
      border-radius: 3px;
      width: 64px;
      margin-bottom: 8px;

      img {
        display: block;
        padding: 8px;
        margin: auto;
      }
    }
    .gender-icon:hover {
      box-shadow: 0 2px 6px 2px rgba(82,98,78,0.30);
    }
    .icon-active {
      box-shadow: 0 2px 6px 3px rgba(82,98,78,0.30);
    }
  }

  .male-btn {
    background: $gradient-vertical;
  }

  .female-btn {
    background: #FFFFFF;
  }

  .separate-word {
    display: inline-block;
    vertical-align: bottom;
    margin: 40px 12px;

    font-size: 12px;
    font-weight: bold;
  }

  .form-button {
    margin-top: 26px;
  }

  .link-button {
    margin-top: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
}