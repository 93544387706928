.weekly-habit-dialog {
  position: relative;

  .form-button {
    width: 170px;
    margin-top: 25px;
  }
  .formatted-text {
    display: inline-block;
    width: 200px;
  }
  .people-flat-icon--1 {
    position: absolute;
    right: -70px;
    top: 15px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -5px 50px no-repeat;
  }
  .people-flat-icon--9 {
    position: absolute;
    left: -70px;
    top: 20px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -320px -320px no-repeat;
  }

  select {
    cursor: pointer;
    appearance: none;
    display: block;
    width: 100px;
    height: 50px;
    margin: auto;
    padding: 0 24px;
    font-size: 16px;
    line-height: 1.75;
    color: transparent;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    word-break: normal;
    text-shadow: 0 0 0 #000;

    &:focus:active ~ .select__arrow {
      transform: rotate(180deg) !important;
      filter: brightness(1.1);
    }

    &::-ms-expand {
      display: none;
    }
  }

  .select__arrow {
    background: url('../../../../assets/img/dropdown-arrow.png') no-repeat;
    background-size: contain;
    width: 28px;
    height: 20px;
    top: 13px;
    right: calc(50% - 40px);
    pointer-events: none;
    position: absolute;
    content: "";
    transform: rotate(0) !important;
    transition-duration: 0.2s;
    transition-property: transform;
  }

  option {
    color: black;
  }
}

