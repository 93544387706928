.select-sport-moments-dialog {
  .dialog-description {
    width: 275px;
    margin: 0 auto;
  }
  .sport-moments {
    padding-bottom: 20px;
  }
  .description {
    font-size: 14px !important;
    color: #939393;
    text-align: center;
    font-style: italic;
  }
  .day-button {
    background: #F4F4F9;
    border: 1px solid #D5D5FF;
    border-radius: 3px;
  }
}