.gotoapp-dialog {
  .dialog-description {
    max-width: 295px;
    margin: 10px auto 25px;
  }

  .form-field {
    box-shadow: none !important;
    display: flex;
    margin: auto;
    padding: 10px;
    width: auto;
    flex-direction: column;
    border: none;
  }

  .badge-link {
    display: inline-block;
    cursor: pointer;

    img {
      display: block;
      margin: auto;
      width: 100%;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}
