@mixin sport-planner-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%);
  }
}

.friend-list-dialog {
  .dialog-wrapper {
    margin: 10px auto 20px !important;
    padding: 0;
    overflow-y: hidden;
  }
}

.friend-list {
  text-align: left;
  margin-top: 24px;
  overflow-y: auto;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  padding: 0 5px;

  @include sport-planner-scrollbar;

  .friend-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 24px;
    background: #FFFFFF;
    box-shadow: 0 9px 12px 0 rgba(184,184,216,0.30);
    border: 1px solid #E3E3EB;
    border-radius: 6px;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 45px;
      vertical-align: middle;
      box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
      border: 1px solid grey;
      margin: 10px
    }
    .fullname {
      font-size: 17px;
      font-weight: bold;
      display: inline-block;
      color: #3F3F3F;
    }

    .sports {
      margin: 0 10px 10px 10px;
      overflow-x: auto;
      white-space: nowrap;
      @include sport-planner-scrollbar;
      
      .sport-category {
        display: inline-block;
        border-right: 1px solid rgba(#ABABC8, 0.2);
        margin-bottom: 6px;
        
        .description {
          display: block;
          white-space: nowrap;
          font-size: 10px;
          margin: 0 10px 10px 8px;
          cursor: default;
        }

        .category-content {
          .sport-item {
            display: table-cell;
            padding: 5px 8px;
            text-align: center;
  
            .sport-icon {
              height: 26px;
            }
            .sport-name {
              white-space: nowrap;
              font-size: 10px;
              cursor: default;
            }
          }
        }
      }
    }
  }
}
