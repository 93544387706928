.weekly-target-dialog {
  position: relative;

  .form-button {
    width: 170px;
    margin-top: 25px;
  }
  .formatted-text {
    display: inline-block;
    width: 200px;
  }
  .people-flat-icon--9 {
    position: absolute;
    left: -70px;
    top: 20px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -320px -320px no-repeat;
  }
}

@mixin rangeThumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: radial-gradient(ellipse at center, rgba(69,203,224,1) 0%, rgba(10,136,168,1) 100%);
  border: none;

  &:hover {
    filter: brightness(1.1);
  }
}

@mixin rangeTrack {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #b2b2b2;
}

.range {
  width: 100%;
  height: 5px;
  position: absolute;
  top: -25px;
}

.range input {
  width: calc(100% + 2px);
  position: absolute;
  top: 2px;
  left: -3px;
  height: 0;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }

  &:focus {
    background: none;
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

.range-labels {
  margin: 60px -15px 30px;
  padding: 0;
  list-style: none;
  display: flex;

  li {
    position: relative;
    float: left;
    width: 85px;
    text-align: center;
    color: #b2b2b2;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &::before {
      position: absolute;
      top: -26px;
      right: 0;
      left: 0;
      content: "";
      margin: 0 auto;
      width: 8px;
      height: 8px;
      background: #b2b2b2;
      border-radius: 50%;
      border: 1px solid deepskyblue;
    }
  }

  .active {
    color: rgba(10,136,168,1);
  }

  .selected::before {
    background: deepskyblue;
  }

  .active.selected::before {
    display: none;
  }
}