.save-email-dialog {
  position: relative;

  .description {
    width: 274px;
    margin: auto;
  }
  .people-flat-container {
    width: 20px;
  }
  .people-flat-icon--5 {
    position: absolute;
    right: 0;
    bottom: -70px;
    height: 171px;
    width: 100px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -215px -154px no-repeat;
  }
}

.identity-button-2 {
  width: 175px;
  display: block;
  margin: 20px auto 40px;
  border: none;
  background: white;
}
