.slider {
  display: block;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  margin: 0;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  border-radius: 50%;
  cursor: pointer;
}

.rotation-item {
  margin-bottom: 20px;
  font-size: 14px;
  .slider {
    margin-top: 15px;
  }
}
.rotation-item__measure {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rotation__input {
  width: 40px;
  padding: 5px;
  border: none;
  border-radius: 3px;
  border: 1px solid lightgrey;
  outline: none;
  text-align: center;
}

.crop-container--centered {
  display: block;
  margin-top: 15px;
  margin-bottom: 30px;
  top: 0;
  left: 0;
}
.crop-container {
  margin: auto;
  position: relative;
  height: 250px;
  width: 250px;
  overflow: hidden;
}

.cropper__rm-btn {
position: absolute;
bottom: -20px;
font-size: 12px;
left: 50%;
transform: translateX(-50%);

&:hover {
color: orangered;
cursor: pointer;
}
}
