.nickname-dialog {
  position: relative;

  .form-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 170px;
    top: 150px;
    margin-bottom: 40px;
  }
  .formatted-text {
    display: inline-block;
    width: 200px;
  }
  .people-flat-icon--1 {
    position: absolute;
    right: -70px;
    top: 15px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -5px 50px no-repeat;
  }
}

