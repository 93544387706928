.avatar-dialog {
  position: relative;

  .form-button {
    width: 230px;
    margin-bottom: 15px;
  }
  .formatted-text {
    display: inline-block;
    width: 250px;
  }
  .user-avatar {
    background-position: center;
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 50%;
    border: 1px solid deepskyblue;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
  }
  .user-avatar--giraffe {
    top: 7px;
    left: 34px;
    object-fit: cover;
  }
  .user-avatar--friend {
    right: 0;
    margin-top: 25px;
    margin-right: 43px;
  }
  .ski-avatar {
    width: calc(100% - 20px);
    height: 150px;
    margin: 30px auto;
    background-size: cover;
    background-position: center;
    padding: 10px;
  }
  .choose-file {
    position:relative;
    display:inline-block;

    .form-button {
      width: 200px;
    }
  }
  .choose-file input[type='file'] {
    -webkit-appearance: none;
    position:absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 48px;
    width: 100%;
    cursor: pointer;
  }
}

.testimgcontainer {
  width: 200px;
}