.friends-dialog {
  .dialog-description {
    max-width: 295px;
    margin: 10px auto;
  }

  .form-field {
    display: flex;
    margin: auto;
    padding: 10px;
    width: auto;
    button, input {
      display: inline-flex;
      background: transparent;
      border: none;
      padding: 0 4px;
    }
    input {
      max-width: 80%;
      flex: 1;
      padding: 0 10px;
      font-size: 16px;
      color: #3F3F3F;
      line-height: 23px;
    }
    button {
      cursor: pointer;
    }
    button:hover {
      filter: drop-shadow(1px 1px 0px grey);
    }
  }
}

.separator {
  display: block;
  height: 4px;
  background-color: grey;
  width: 85%;
  margin: 20px auto;
}