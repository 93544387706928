.name-dialog {
  position: relative;

  .form-button {
    width: 170px;
    top: 75px;
    margin-bottom: 40px;
  }
  .form-button--link {
    bottom: -90px;
  }
  .form-button,
  .form-button--link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }
  .formatted-text {
    display: inline-block;
    width: 200px;
  }
  .people-flat-icon--1 {
    position: absolute;
    right: -70px;
    top: 15px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -5px 50px no-repeat;
  }
  .people-flat-icon--9 {
    position: absolute;
    left: -70px;
    top: 20px;
    height: 210px;
    width: 180px;
    transform: scale(0.6);
    background: url('../../../../assets/img/sport-people-flat-icons.png') -320px -320px no-repeat;
  }
}

