@import '../../App.scss';
$dialog-img-wave: url("../../assets/img/img_BackGroundWaves3.png");

.background {
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  overflow: auto;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;

  .profile {
    width: fit-content;
  }

  .wrapper {
    position: relative;
    width: 100%;
  }

  .dialog-content {
    display: block;
    margin: auto;
    border-radius: 18px;
    background: #F6F6F9;
    padding: 18px;
    text-align: center;
    height: 100%;
    
    .modal-header {
      position: relative;

      .pictogram {
        width: 143px;
        height: 104px;
        margin: 80px auto 0;
        display: flex;
      }
  
      .close-btn {
        position: absolute;
        right: 0;
        z-index: 999;
        cursor: pointer;
        font-weight: bold;
      }
      .close-btn:hover {
        filter: drop-shadow(0px 0px 1px grey);
      }
    }
    .description {
      font-size: 12px;
    }
  }

  .profileDialog {
    width: 80%;
    padding: 25px;
  }

  @media(max-width: 375px) {
    .profileDialog {
      max-width: 100%;
      padding: 25px;
    } 
  }
  
  .default-dialog {
    width: 275px;
    display: flex;
    align-items: center;
  }
  
  .dialog-wrapper {
    padding: 60px 0;
    margin: auto;
  }
  
  @media (max-width: 340px) {
    .dialog-wrapper {
      max-width: 320px;
    }
  }

  .friend-dialog {
    width: 320px;
    background: $gradient-vertical;
    padding: 2px;

    .close-btn {
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      right: 10px !important;
      top: -10px;
      line-height: 40px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
    }

    .wrapper {
      display: block;
      margin: auto;
      border-radius: 18px;
      background: #F6F6F9;
      padding: 18px 0;
      text-align: center;

      background: $dialog-img-wave no-repeat scroll center center / 100% 30%,
        linear-gradient(203deg, #BEBEBE, #FFFFFF);
      background-position-y: 100%;
    }

    .title {
      font-size: 28px;
    }

    .user {
      background: $gradient-horizontal;
      border-radius: 45px;
      display: inline-block;
      margin: 30px auto 50px auto;
      box-shadow: 0 8px 15px 0 rgba(255,179,0,0.70);
      
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 45px;
        margin: 7px;
        vertical-align: middle;
      }

      .full-name {
        display: inline-block;
        vertical-align: middle;
        margin-right: 28px;
        font-weight: bold;

        .first-name {
          font-size: 18px;
          color: #FFFFFF;
          line-height: 18px;
        }
    
        .last-name {
          font-size: 18px;
          color: #FFFFFF;
          line-height: 18px;
        }
      }
    }
  }
}

.background--white {
  background: #FFFFFF;
}

.dialog-wrapper {
  height: auto !important;
}

.prompt-logo {
  @extend .logo-base;
}

.friend-avatar__container {
  display: block;
  width: 100px;
  height: 100px;
  padding: 10px;
  border: 1px solid deepskyblue;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
  margin: 30px auto;
  border-radius: 50%;
}

.friend-avatar {
  display: block;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.text-italic {
  font-style: italic;
}

.text-sz-14 {
  font-size: 12px;
}
