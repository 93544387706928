@import '../../../../App';

.control-dots {
  outline: none;
  padding: 0 !important;
  margin: -2px 0 !important;

  li.dot {
    outline: none;
    background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%) !important;
  }
}

.control-arrow {
  top: 50% !important;
  transform: translateY(-50%);
  height: 50px;
  border-radius: 4px;
  opacity: 0.9 !important;
  font-size: 24px !important;
  background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%) !important;
  border: 1px #f76d1d solid !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.carousel-wrapper {
  &.carousel-dialog {
    padding: 0 !important;
  }
}

.image-container {
  max-width: calc(100% - 35px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.image-carousel__btn {
  position: relative;
  transform: translateX(-50%);
  display: block;
  left: 50%;
  top: 50px;

  @extend .form-button;
  min-width: 120px;
  padding: 8px 30px;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.2);
}

.image-carousel__empty {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 15px;
  transform: translate(-50%, -50%);
}

.image-carousel__arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 8px;
  margin: 2px 2px;
  cursor: pointer;
  border: 2px solid #00CA9D;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  top: 17px;
  color: #00CA9D;
  font-weight: 600;
  font-size: 20px;
  line-height: 14px;
  user-select: none;
  transform: translateX(calc(-50% - 7px));
  outline: none !important;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: #00CA9D;
    color: white;
  }
}

.image-carousel__header {
  .logo {
    @extend .logo-base;
  }
  .image-carousel__arrow-left {
    @extend .image-carousel__arrow;

    left: 40px;
  }
}

.image-carousel__arrow-right {
  @extend .image-carousel__arrow;
  right: calc(50% - 70px);
}

.image-carousel__arrow--hidden {
  visibility: hidden;
}

.image-carousel__dot-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  justify-items: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  bottom: 70px;
}

.carousel .slide {
  background-color: white !important;
  margin: -2px 0 -5px;
}

.background--white {
  .dialog-content {
    background: white !important;
    padding: 0 18px !important;
  }
  .carousel-wrapper {
    margin: 0 auto !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
