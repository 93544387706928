@import '../../App.scss';

.sport-moment {
  display: table;
  margin: 0 calc(50% );
  transform: translateX(-50%);
  .side-label {
    font-size: 14px;
    color: #3F3F3F;
    text-align: center;
  }
  td:nth-child(1) {
    min-width: 43px;
    padding: 0 4px;
  }
  .day-names {
    th {
      padding-top: 10px;
      font-size: 14px;
    }
  }
  .day-button {
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #D7D7F9;
    border-radius: 3px;
    margin: 1px;
    
    @media (max-width: 425px) {
      width: 20px;
      height: 18px;
    }

    @media (max-width: 320px) {
      width: 16px;
      height: 14px;
    }
  }
  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox] + .day-button {
    cursor: pointer;
    background: #F4F4F9;
  } 
  input[type=checkbox]:checked + .day-button {
    background: $gradient-vertical;
  } 
}
