body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-tap-highlight-color: transparent;
  background-color: #f5f5f9;
  * {
    font-family: 'Montserrat';
  }
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Bold.otf);
  font-weight: bold;
}

.img--hand {
  position: absolute;
  top: -75px;
  left: -10px;
  width: 50px;
  height: 50px;
  background: url('./assets/img/hand.gif') no-repeat center;
  background-size: contain;
}

@mixin sport-planner-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(90deg, #f76d1d 10%, #ffcc27 100%);
  }
}