@import '../../../App.scss';
$icon-background: #40CA9D;

.menu {
  text-align: center;
  .menu-option {
    display: flex;
    margin: 16px 12px;
    cursor: pointer;

    .pictogram {
      position: absolute;
      width: 65px;
      height: 56px;
      display: inline-flex;
      background: $icon-background;
      border-radius: 11px 0 0 11px;
      .go-to-app-icon {
        width: auto!important;
      }
      img {
        margin: auto;
        width: 36px;
        height: 32px;
      }
    }
    .name {
      background: $gradient-vertical;
      border-radius: 11px;
      flex: 1;
      padding: 17px 17px 17px 90px;
      color: white;
      text-align: left;
      display: block
    }
    .title-option {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .menu-option:hover {
    .pictogram {
      background: darken($icon-background, 6);
    }
    .name {
      background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ), $gradient-vertical;
    }
  }

  .menu-option:active {
    .pictogram {
      background: darken($icon-background, 12);
    }
    .name {
      background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), $gradient-vertical;
    }
  }
}